import * as React from "react";

import PageLayout from "../../components/PageLayout";
import Header from "../../components/Header";
import ContactUsSection from "../../sections/ContactUs/ContactUsSection";
import Footer from "../../components/Footer";

const ContactUs = () => {
  return (
    <>
      <Header />
      <PageLayout title="Contact Us">
        <ContactUsSection />
        <Footer />
      </PageLayout>
    </>
  );
};

export default ContactUs;
